export const vars = {
  API_ROUTE: 'pfs3/wapi/',
  SUCCESS_API_CODE: '000',
  DEFAULT_LANGUAGE: 'en',
  METHODS_ICON: {
    0: 'sp0.svg',
    1: 'sp1.svg',
    2: 'sp2.svg',
    3: 'sp3.svg',
    4: 'sp4.svg',
    5: 'sp5.svg',
    6: 'sp6.svg',
    7: 'sp7.svg',
    8: 'sp8.svg',
    9: 'sp9.svg',
    10: 'sp10.svg',
    11: 'sp11.svg',
    12: 'sp12.svg',
    13: 'sp12.svg',
    14: 'sp14.svg',
    15: 'sp15.svg',
    16: 'sp16.svg',
    18: 'sp18.svg',
    22: 'sp22.svg',
    24: 'sp24.svg',
    25: 'sp25.svg',
  },
  KIND_IDS: {
    CRYPTO: 4,
    BY_REQUISITES: 7,
  },
  AUTH_TYPE: {
    SIMPLE: 1,
    TWO_FACTOR: 2,
  },
  STATUS: {
    NEW: 1,
    SUCCESS: 2,
    FAILURE: 3,
    PENDING: 4,
    SECOND_SUCCESS: 5,
    ICONS: {
      2: 'outline-check-circle-outline-24-px.svg',
      3: 'highlight-off-24-px.svg',
      4: 'hourglass-full-24-px.svg',
      5: 'outline-check-circle-outline-24-px.svg',
    },
  },
  PAYMENT_SYSTEM_TYPE_ID: {
    SHARPAY: 0,
    CRYPTO: 15,
  },
  VIEW: {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
  }
};
/*
0: {ID: 1, Name: "AdvancedCash"}
1: {ID: 3, Name: "Connectum"}
2: {ID: 4, Name: "PerfectMoney"}
3: {ID: 2, Name: "WebMoney"}
4: {ID: 9, Name: "Payeer"}
5: {ID: 0, Name: "SharPay"}*/
