import {vars} from './variables';
import toast from 'react-hot-toast';

const {API_ROUTE, METHODS_ICON, SUCCESS_API_CODE} = vars;

const notify = (message) => toast.error(message);

async function sendRequest(urlPath, payload) {
  const language = window.localStorage.getItem('language') || 'en';

  payload.Language = language.toUpperCase();

  const options = {
    method: 'POST',
    credentials: 'same-origin',
    mode: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };

  const apiUrl = `${API_ROUTE}${urlPath}`;

  try {
    const response = await fetch(apiUrl, options);
    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      throw new TypeError('Server sent not JSON');
    }
    const result = await response.json();

    if (result && result['ResponseCode'] === '401') {
      window.localStorage.removeItem('user');
    }

    return result;
  } catch (e) {
    console.error(e);
  }
}

/*
function getFromSearchString(search) {
	const hashes = search.slice(search.indexOf('?') + 1).split('&');
	const params = {};
	hashes.map((hash) => {
		const [key, val] = hash.split('=');
		return params[key] = decodeURIComponent(val);
	});
	return params;
}*/

function getFromSearchString(pathname, urlidPath = 3) {
  return pathname.split('/')[urlidPath] || '';
}

async function sha512(str) {
  return crypto.subtle.digest('SHA-512', new TextEncoder('utf-8').encode(str)).then(buf => {
    return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
  });
}

function getMethodIconById(id) {
  return `img/${METHODS_ICON[id]}`;
}

async function checkOrder(urlid, callback) {
  const order = await sendRequest('CheckoutOrder/GetOrder', {
    Params: {
      Urlid: urlid
    }
  });

  if (order && order['ResponseCode'] === SUCCESS_API_CODE) {
    callback({
      ...order['Response']
    });
  } else {
    notify(order.ResponseCode + ' ' + order.ResponseText);
  }
}

const groupToMap = (list, keyGetter, sortKey) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  if (sortKey) {
    for (let i = 0; i < map.size; i++) {
      map.values().next().value.sort((a, b) => a[sortKey] - b[sortKey])
    }
  }
  return map;
};

const copyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

const fetchCheckoutOrder = async (urlid, setStatusResponse, recheckStatusId) => {
  const endpoint = `${API_ROUTE}CheckoutOrder/GetOrderStatus`;
  const language = window.localStorage.getItem('language') || 'en';

  const payload = {
    Params: {
      Urlid: urlid,
    },
    Language: language.toUpperCase(),
  };

  const options = {
    method: 'POST',
    credentials: 'same-origin',
    mode: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };

  const response = await fetch(endpoint, options);

  if (response.status === 502) {
    // connection timeout, need reconnect
    await new Promise(resolve => setTimeout(resolve, 1000));
    await fetchCheckoutOrder(urlid, setStatusResponse, recheckStatusId);
  } else if (response.status !== 200) {
    // try to reconnect after 1sec
    await new Promise(resolve => setTimeout(resolve, 1000));
    await fetchCheckoutOrder(urlid, setStatusResponse, recheckStatusId);
  } else {
    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      throw new TypeError('Server sent not JSON');
    }
    const result = await response.json();

    if (result && result.ResponseCode === SUCCESS_API_CODE) {
      const responseStatusID = parseInt(result.Response.MerchantOrderStatusID, 10);
      if (!Number.isNaN(responseStatusID)) {
        if (responseStatusID === recheckStatusId) {
          // check status again
          await new Promise(resolve => setTimeout(resolve, 2000));
          await fetchCheckoutOrder(urlid, setStatusResponse, recheckStatusId);
        } else {
          // update status page

          setStatusResponse({
            statusId: responseStatusID,
            name: result.Response.MerchantOrderStatusName,
            count: result.Response.Cnt,
          });
        }
      }
    } else {
      notify(result.ResponseText);
    }
  }
};


export {
  sendRequest, getFromSearchString, sha512, getMethodIconById, checkOrder, groupToMap, copyTextToClipboard,
  fetchCheckoutOrder, notify,
};
